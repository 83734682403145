@import url('https://fonts.googleapis.com/css2?family=Mooli&display=swap');

* {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}
input[type="checkbox"] {
    transform: scale(1.8) !important; 
    margin-right: 10px !important;  
    border-color: rgb(52, 69, 52) !important;
}
input[type=checkbox]:checked {
    border-color: green;
  }
 
#signup-sec {
    margin-top: 25px;
    box-sizing: border-box;
    width: auto;
}

.container-fluid {
    max-width: 600px;
    margin: 40px auto;
    padding: 30px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    background-color: white;
    font-family: 'Arial', sans-serif;
    display: flexbox;
    flex-direction: column;
    align-items: center;
    position: relative;
}

#h1 {
    text-align: center;
    color: #202124;
    font-size: 19px;
    font-weight: 200;
    font-family: "Mooli", cursive; 
    margin-bottom: auto;
    margin-top: 15px;
    border-bottom: 2mm ridge rgb(92, 230, 240);
}


label {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-weight: bold;
    margin: 15px 0;
    color: black;
    text-align: left;
}

input[type="text"],
input[type="email"],
input[type="tel"],
select {
    width: 100%;
    padding: 10px 12px;
    margin-top: 5px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    font-size: 14px;
    transition: border-color 0.3s;
    outline: none;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

input:focus,
select:focus {
    border-color: #4285f4;
}

.error {
    border-color: red !important;

}

.btn {
    margin: 15px 0;
    padding: 10px 24px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.btn-primary,
.btn-secondary {
    align-self: flex-end;
}

.btn-secondary {
    background-color: #f5f5f5;
    color: #202124;
    margin-right: 10px;
}

.btn-primary {
    background-color: #4285f4;
    color: white;
}

.btn-secondary:hover {
    background-color: #e0e0e0;
}

.btn-primary:hover {
    background-color: #357ab8;
}





#fn {
    display: flex;
    width: auto;
}

#first-last-div {
    display: inline-flex;
    position: relative;
}

#last-div,
#first-div {
    width: 98%;
    display: flex;
    height: auto;
}

#last-div {
    height: 50px;
    margin-top: 35px;
}



#imgUpload {
    width: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

/* ==========Navigation==================== */

.horizontal-nav {
    background-color: rgb(10, 39, 80);
    text-align: center;
    width: 100%;
}

#nx-nav {
    margin: 0;
    padding: 0;
}

.horizontal-nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: auto;
    margin: 0;
    padding: 0;
}

.horizontal-nav li {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
}

.horizontal-nav a {
    text-decoration: none;
    color: white;
}

.horizontal-nav a:hover {
    color: #a0732f;
}

#logo {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    position: absolute;
}

#nav-li-id a {
    display: flex;
    position: absolute;
    justify-content: center;
    justify-self: center;
    margin-right: auto;
    margin-left: auto;
    width: 90%;
}

.navbar-nav {
    justify-content: center;
}

#Second {
    display: flex;
    justify-content: right;
    margin: 0;
    padding: 0;
}
/* Style the label that wraps the checkbox */
.custom-checkbox {
    display: inline-block;
    position: relative;
    padding-left: 25px; /* Space for the custom checkbox */
    margin-right: 15px;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
}

/* Hide the default checkbox input */
.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create the custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px; /* Size of the custom checkbox */
    width: 25px;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Style the custom checkbox when checked */
.custom-checkbox input:checked ~ .checkmark {
    background-color: #2196F3; /* Change to your desired color */
}

/* Create the checkmark symbol */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark symbol when checked */
.custom-checkbox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark symbol */
.custom-checkbox .checkmark:after {
    left: 9px; /* Position the checkmark symbol */
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg); /* Rotate the symbol to make it look like a checkmark */
}




/* ============================================ */
/* ============================================ */
/* ============================================ */
/* ============================================ */
/* ============================================ */
/* ============================================ */
/* ============================================ */
/* ============================================ */
/* ============================================ */
/* ============================================ */
/* ============================================ */
/* ============================================ */
/* ============================================ */
/* ============================================ */
/* Responsive Styles */
@media screen and (max-width: 768px) {


    #signup-sec {
        margin-top: 25px;
        box-sizing: border-box;
        width: auto;
    }

    #h3-text {
        font-family: "Zeyada", cursive;
        font-size: 45px;
        margin-bottom: auto;
        margin-top: 25px;
        border-bottom: 2mm ridge rgb(240, 92, 92);
    }

    #form-items {
        width: auto;
        margin: 50px auto;
        padding: 20px;
        border: 2px solid #ccc;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        background-color: #fff;
        border-radius: 25px;
        display: block;
    }

    #fn {
        display: flex;
    }


    #imgUpload {
        width: auto;
        /* margin-top: 20px; */
        display: flex;
        justify-content: center;
    }
/* Style the label that wraps the checkbox */
.custom-checkbox {
    display: inline-block;
    position: relative;
    padding-left: 25px; /* Space for the custom checkbox */
    margin-right: 15px;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
}

/* Hide the default checkbox input */
.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create the custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px; /* Size of the custom checkbox */
    width: 25px;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Style the custom checkbox when checked */
.custom-checkbox input:checked ~ .checkmark {
    background-color: #2196F3; /* Change to your desired color */
}

/* Create the checkmark symbol */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark symbol when checked */
.custom-checkbox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark symbol */
.custom-checkbox .checkmark:after {
    left: 9px; /* Position the checkmark symbol */
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg); /* Rotate the symbol to make it look like a checkmark */
}

    /* ============================================
/* ============================================
/* ============================================
NAvigation styling
*/

    .horizontal-nav {
        background-color: rgb(10, 39, 80);
        text-align: center;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .horizontal-nav ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: auto;
    }

    .horizontal-nav li {
        display: flex;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .horizontal-nav a {
        text-decoration: none;
        color: white;
    }

    .horizontal-nav a:hover {
        color: #a0732f;
    }


    #logo {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        position: absolute;
        display: flex;
        z-index: 1;
        transition: 3ms;
    }

    /* #allform{
        margin-top: ;
    } */

    #log-link a {
        position: absolute;
        display: flex;
        justify-content: center;
        width: auto;
        /* margin-top: 1000px; */
        margin-right: auto;
        margin-left: auto;
    }

    .navbar-nav {
        justify-content: center;
    }

    #Second {
        display: flex;
        justify-content: flex-end;
    }
    /* Style the label that wraps the checkbox */
.custom-checkbox {
    display: inline-block;
    position: relative;
    padding-left: 25px; /* Space for the custom checkbox */
    margin-right: 15px;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
}

/* Hide the default checkbox input */
.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create the custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px; /* Size of the custom checkbox */
    width: 25px;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Style the custom checkbox when checked */
.custom-checkbox input:checked ~ .checkmark {
    background-color: #2196F3; /* Change to your desired color */
}

/* Create the checkmark symbol */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark symbol when checked */
.custom-checkbox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark symbol */
.custom-checkbox .checkmark:after {
    left: 9px; /* Position the checkmark symbol */
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg); /* Rotate the symbol to make it look like a checkmark */
}


    /* Responsive Styles */
    @media screen and (max-width: 820px) {


        #signup-sec {
            margin-top: 25px;
            box-sizing: border-box;
            width: auto;
            overflow-x: hidden;
        }

        /* ========================================== */
        /* ===NAVIGAAITON */
        /* ========================================== */
        .horizontal-nav {
            background-color: rgb(10, 39, 80);
            width: auto;
            height: auto;
            margin: 0;
            padding: 0;
        }

        .horizontal-nav ul {
            list-style: none;

            padding: 0;
            margin: 0;
            width: auto;
        }

        .horizontal-nav li {
            display: grid;
            width: 100%;
            margin: 0;
            padding: 0;
        }

        .horizontal-nav a {
            text-decoration: none;
            color: white;
            text-align: right;
        }

        #logo {
            width: 90px;
            height: 90px;
            border-radius: 50%;
            position: absolute;
            display: flex;
        }

        #log-link a {
            position: absolute;
            display: flex;
            justify-content: center;
            width: auto;
            margin-top: 50px;
            margin-right: auto;
            margin-left: auto;
        }

        #Second {
            display: block;
            text-align: center;
            justify-content: flex-end;
        }
    }


    #fn {
        display: flex;
    }

    #imgUpload {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        width: auto;
    }
/* Style the label that wraps the checkbox */
.custom-checkbox {
    display: inline-block;
    position: relative;
    padding-left: 25px; /* Space for the custom checkbox */
    margin-right: 15px;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
}

/* Hide the default checkbox input */
.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create the custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px; /* Size of the custom checkbox */
    width: 25px;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Style the custom checkbox when checked */
.custom-checkbox input:checked ~ .checkmark {
    background-color: #2196F3; /* Change to your desired color */
}

/* Create the checkmark symbol */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark symbol when checked */
.custom-checkbox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark symbol */
.custom-checkbox .checkmark:after {
    left: 9px; /* Position the checkmark symbol */
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg); /* Rotate the symbol to make it look like a checkmark */
}

}

@media screen and (max-width: 912px) {


    #signup-sec {
        margin-top: 25px;
        box-sizing: border-box;
        width: auto;
        overflow-x: hidden;
    }

    #h3-text {
        font-family: "Zeyada", cursive;
        font-size: 45px;
        margin-bottom: auto;
        margin-top: 25px;
        border-bottom: 2mm ridge rgb(240, 92, 92);
    }

    #fn {
        display: flex;
    }

    #logo {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        position: absolute;
        display: flex;
    }

    #log-link a {
        position: absolute;
        display: flex;
        justify-content: center;
        width: auto;
        margin-top: 50px;
        margin-right: auto;
        margin-left: auto;
    }



    #imgUpload {
        display: flex;
        justify-content: center;
        width: auto;
    }
    /* Style the label that wraps the checkbox */
.custom-checkbox {
    display: inline-block;
    position: relative;
    padding-left: 25px; /* Space for the custom checkbox */
    margin-right: 15px;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
}

/* Hide the default checkbox input */
.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create the custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px; /* Size of the custom checkbox */
    width: 25px;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Style the custom checkbox when checked */
.custom-checkbox input:checked ~ .checkmark {
    background-color: #2196F3; /* Change to your desired color */
}

/* Create the checkmark symbol */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark symbol when checked */
.custom-checkbox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark symbol */
.custom-checkbox .checkmark:after {
    left: 9px; /* Position the checkmark symbol */
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg); /* Rotate the symbol to make it look like a checkmark */
}


}


/* Responsive adjustments */
@media (max-width: 640px) {
    .container {
        margin: 20px 10px;
        padding: 20px;
    }

    h1 {
        font-size: 20px;
    }

    label,
    .btn {
        font-size: 14px;
    }

    input,
    select {
        font-size: 12px;
    }

    .btn {
        margin-top: 20px;
        padding: 8px 16px;
    }

    #logo {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        position: absolute;
        display: flex;
    }

    #log-link a {
        position: absolute;
        display: flex;
        justify-content: center;
        width: auto;
        margin-top: 50px;
        margin-right: auto;
        margin-left: auto;
    }
    /* Style the label that wraps the checkbox */
.custom-checkbox {
    display: inline-block;
    position: relative;
    padding-left: 25px; /* Space for the custom checkbox */
    margin-right: 15px;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
}

/* Hide the default checkbox input */
.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create the custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px; /* Size of the custom checkbox */
    width: 25px;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Style the custom checkbox when checked */
.custom-checkbox input:checked ~ .checkmark {
    background-color: #2196F3; /* Change to your desired color */
}

/* Create the checkmark symbol */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark symbol when checked */
.custom-checkbox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark symbol */
.custom-checkbox .checkmark:after {
    left: 9px; /* Position the checkmark symbol */
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg); /* Rotate the symbol to make it look like a checkmark */
}


}